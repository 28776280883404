import './App.less'
import { useState, useEffect, useRef } from 'react'
import { libnoise } from 'libnoise'
import * as geo from 'd3-geo'
import * as d3 from 'd3'
import * as chroma from 'chroma-js'
import { Card, Button, Space, Row, Col, Divider } from 'antd'
import { GithubOutlined } from '@ant-design/icons'

const { height, width } = { height: 100, width: 100 } //useWindowDimensions()

const quality = libnoise.QualityMode.LOW
const numThresholds = 16

const generatePaths = () => {
  const noise = new libnoise.generator.Perlin(
    0.3, // freq
    0.1, // lacunarity
    12, // octave count
    1.4, // persistence
    Math.random() * 100, // seed
    quality // quality
  )

  const data = Array.from({ length: width * height }).map((_, i) => {
    const x = i % width
    const y = i / height
    return noise.getValue(x, y, 1)
  })

  // find largest and smallest values in data
  const min = Math.min(...data)
  const max = Math.max(...data)
  // create an array of thresholds between min and max
  const thresholds = [...Array(numThresholds).keys()].map(
    i => min + (max - min) * (i / (numThresholds - 1))
  )

  const colorsBase = chroma
    .scale(['#295939', '#e9b0df'])
    .domain([1, numThresholds], numThresholds, 'log')
    .colors(numThresholds)

  const thresholdColors = thresholds.map((t, i) => [colorsBase[i]])

  const contours = d3.contours().size([width, height])
  const projection = geo.geoIdentity().scale(1.2).translate([-25, -25])
  const path = d3.geoPath(projection)

  return thresholds.map((threshold, i) => ({
    d: path(contours.contour(data, threshold)),
    color: thresholdColors[i]
  }))
}

function Background () {
  const [paths, setPaths] = useState(generatePaths())

  // generate new paths every numThresholds seconds
  /*useEffect(() => {
    const interval = setInterval(() => {
      setPaths(generatePaths())
    }, numThresholds * 1000)
    return () => clearInterval(interval)
  }, [])*/

  const KeyframesStyle = (
    <style>
      {`
    @keyframes colorCycle {
      ${paths.map(
        ({color}, index) => `
        ${(index * 100) / (numThresholds - 1)}% {
          stroke: ${color};
        }
      `
      )}
    }
    `}
    </style>
  )

  return (
    <>
      {KeyframesStyle}
      <svg
        viewBox={`0 0 ${width / 2} ${height / 2}`}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh'
        }}
      >
        <g>
          {paths.map(({ d, color }, index) => (
            <path
              key={d}
              d={d}
              fill={'#295939'}
              stroke={color}
              strokeWidth={0.05}
              strokeLinejoin='round'
              style={{
                animationDelay: `${index}s`,
                animationDuration: `${3}s`,
                animationName: 'colorCycle',
                animationIterationCount: 'infinite',
                animationDirection: 'alternate',
                animationTimingFunction: 'ease-in-out',
                animationFillMode: 'both'
              }}
              className='contour'
            />
          ))}
        </g>
      </svg>
    </>
  )
}

function App () {
  return (
    <div className='app'>
      <Background />
      <div className='header'>
        <Card
          className='headerContent'
          bordered={false}
          title={<h1 className={'title'}>milquetoast</h1>}
        >
          <Divider>language</Divider>
          <p>
            <ul>
              <li>
                <a href='https://magnetboard.io'>magnetboard.io</a> <br />
                <i>collaborative fridge magnets</i>
              </li>
              <li>
                <a href='https://hanzi.gg'>hanzi.gg</a> <br />
                <i>hanzi guessing game</i>
              </li>
              <li>
                <a href='/milquewordgen'>milquewordgen</a> <br />
                <i>conlang word generator</i>
              </li>
            </ul>
          </p>
          <Divider>games</Divider>
          <p>
            <ul>
              <li>
                <a href='/scattershell'>Sailsongs of Scattershell</a>
              </li>
              <li>
                <a href='/scattershell-islands'>Scattershell Islands</a>
              </li>
              <li>
                <a href='/craigs-oregano'>Craig's Oregano</a>
              </li>
            </ul>
          </p>
          <Divider>ham radio</Divider>
          <p>
            <ul>
              <li>
                <a href='https://ft8.live'>ft8.live</a> <br /> realtime spotting
                tool
              </li>
            </ul>
          </p>
        </Card>
      </div>
    </div>
  )
}

export default App
